var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "ruleForm",
      attrs: { model: _vm.contractFormData },
    },
    [
      _c("h1", { staticClass: "sub-module-title" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c("div", { staticClass: "sub-module-block" }, [
        _c(
          "div",
          { staticClass: "div-frac-average-14" },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Term Contract Type",
                  rules: _vm.rules.reqRule,
                  prop: "termContractType",
                },
              },
              [
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.contractFormData.termContractType,
                    callback: function ($$v) {
                      _vm.$set(_vm.contractFormData, "termContractType", $$v)
                    },
                    expression: "contractFormData.termContractType",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Mitigating Company",
                  rules: _vm.rules.reqRule,
                  prop: "mitigatingCompany",
                },
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      "value-key": "counterpartySysOrganizationId",
                      disabled: "",
                    },
                    model: {
                      value: _vm.contractFormData.mitigatingCompany,
                      callback: function ($$v) {
                        _vm.$set(_vm.contractFormData, "mitigatingCompany", $$v)
                      },
                      expression: "contractFormData.mitigatingCompany",
                    },
                  },
                  _vm._l(
                    _vm.sortByAlphabetical(
                      _vm.companyOptionList,
                      "counterpartyCompanyName"
                    ),
                    function (item) {
                      return _c("el-option", {
                        key: item.counterpartySysOrganizationId,
                        attrs: {
                          label: item.counterpartyCompanyName,
                          value: item.counterpartySysOrganizationId,
                        },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Contract Number",
                  rules: _vm.rules.reqRule,
                  prop: "contractNo",
                },
              },
              [
                _c("el-input", {
                  attrs: {
                    disabled: _vm.isAllFieldsDisabled || !_vm.hasEditPermission,
                  },
                  model: {
                    value: _vm.contractFormData.contractNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.contractFormData, "contractNo", $$v)
                    },
                    expression: "contractFormData.contractNo",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Contract Date Range",
                  rules: _vm.rules.reqRule,
                  prop: "dateRange",
                },
              },
              [
                _c("el-date-picker", {
                  attrs: {
                    disabled: _vm.isAllFieldsDisabled || !_vm.hasEditPermission,
                    type: "daterange",
                    "range-separator": "-",
                    "start-placeholder": "Start Date",
                    "end-placeholder": "End Date",
                  },
                  on: { change: _vm.hanleChangeDate },
                  model: {
                    value: _vm.contractFormData.dateRange,
                    callback: function ($$v) {
                      _vm.$set(_vm.contractFormData, "dateRange", $$v)
                    },
                    expression: "contractFormData.dateRange",
                  },
                }),
              ],
              1
            ),
            _vm.termContractType !== _vm.TERM_CONTRACT_TYPE.TC
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Payment Terms",
                      rules: _vm.rules.reqRule,
                      prop: "paymentTerm",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          disabled:
                            _vm.isAllFieldsDisabled || !_vm.hasEditPermission,
                        },
                        on: { change: _vm.handlePaymentTermChange },
                        model: {
                          value: _vm.contractFormData.paymentTerm,
                          callback: function ($$v) {
                            _vm.$set(_vm.contractFormData, "paymentTerm", $$v)
                          },
                          expression: "contractFormData.paymentTerm",
                        },
                      },
                      _vm._l(_vm.PAYMENT_TERM_OPTIONS, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.termContractType !== _vm.TERM_CONTRACT_TYPE.TC &&
            _vm.contractFormData.paymentTerm === "SPECIFIC_DATE"
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Payment Term Date",
                      prop: "paymentTermDate",
                      rules: _vm.rules.reqRule,
                    },
                  },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        disabled:
                          _vm.isAllFieldsDisabled || !_vm.hasEditPermission,
                        type: "date",
                      },
                      model: {
                        value: _vm.contractFormData.paymentTermDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.contractFormData, "paymentTermDate", $$v)
                        },
                        expression: "contractFormData.paymentTermDate",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.termContractType !== _vm.TERM_CONTRACT_TYPE.TC &&
            _vm.contractFormData.paymentTerm !== "SPECIFIC_DATE"
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Credit Days",
                      prop: "creditDay",
                      rules: _vm.rules.reqRule,
                    },
                  },
                  [
                    _c("el-input-number", {
                      attrs: {
                        disabled:
                          _vm.contractFormData.paymentTerm ===
                            "CASH_IN_ADVANCE" ||
                          _vm.isAllFieldsDisabled ||
                          !_vm.hasEditPermission,
                        controls: false,
                        precision: 0,
                        min: 0,
                      },
                      model: {
                        value: _vm.contractFormData.creditDay,
                        callback: function ($$v) {
                          _vm.$set(_vm.contractFormData, "creditDay", $$v)
                        },
                        expression: "contractFormData.creditDay",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Terms and Condition",
                  prop: "termsAndConditions",
                },
              },
              [
                _c("el-input", {
                  attrs: {
                    disabled: _vm.isAllFieldsDisabled || !_vm.hasEditPermission,
                  },
                  model: {
                    value: _vm.contractFormData.termsAndConditions,
                    callback: function ($$v) {
                      _vm.$set(_vm.contractFormData, "termsAndConditions", $$v)
                    },
                    expression: "contractFormData.termsAndConditions",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Port", prop: "port" } },
              [
                _c("el-input", {
                  attrs: {
                    disabled: _vm.isAllFieldsDisabled || !_vm.hasEditPermission,
                  },
                  model: {
                    value: _vm.contractFormData.port,
                    callback: function ($$v) {
                      _vm.$set(_vm.contractFormData, "port", $$v)
                    },
                    expression: "contractFormData.port",
                  },
                }),
              ],
              1
            ),
            ["EX_WHARF", "DELIVERED"].includes(_vm.termContractType)
              ? _c(
                  "el-form-item",
                  {
                    class:
                      _vm.attachmentsList &&
                      _vm.attachmentsList.length < 1 &&
                      "margin-top",
                    attrs: { label: "", prop: "attachments" },
                  },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "upload-attachment",
                        attrs: {
                          multiple: "",
                          action: _vm.fileUploadUrl,
                          "file-list": _vm.attachmentsList,
                          "on-success": _vm.handleAttachmentUploadSuccess,
                          "on-remove": _vm.handleRemove,
                          "on-preview": _vm.handlePreview,
                          disabled:
                            _vm.isAllFieldsDisabled || !_vm.hasEditPermission,
                        },
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { round: "", outline: "", size: "small" } },
                          [_vm._v(_vm._s(_vm.$t("uploadFile")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("file-preview", {
        attrs: { visible: _vm.isOpenFilePreview, file: _vm.previewFile },
        on: {
          "update:visible": function ($event) {
            _vm.isOpenFilePreview = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }